import { Outlet } from "react-router-dom";
import DibtechLogo from "../assets/logo.png";
import LoginBackground from "../assets/bg_login.png";
import { tw } from "twind";

const AuthLayout = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${LoginBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className={tw`h-screen flex justify-center items-center`}
    >
      <div
        className={tw`bg-white grid grid-cols-2 md:px-8 md:py-20 px-6 py-10 shadow-lg rounded-lg md:max-w-3xl w-full relative mx-4`}
      >
        <div
          className={tw`flex items-center justify-center md:col-span-1 col-span-2 `}
        >
          <img
            src={DibtechLogo}
            alt="dibtech-logo"
            className={tw`md:mx-12 md:w-48 w-36 md:my-8 mb-4`}
          />
        </div>
        <div className={tw`md:col-span-1 col-span-2 md:px-2`}>
          <Outlet />
        </div>
      </div>
      <div className={tw`border-t fixed bottom-0 w-full text-center py-3`}>
        Powered by{" "}
        <a href="https://dibtech.com.au/" className={tw`font-medium`}>
          Dibtech Business Solution
        </a>
      </div>
    </div>
  );
};

export default AuthLayout;
